import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/copy.svg'


const _hoisted_1 = { class: "box-card pt-28 pb-28 pl-40 pr-40" }
const _hoisted_2 = { class: "fs-16 fw-700 fc-primary mt-12" }
const _hoisted_3 = { class: "fs-14 mt-12" }
const _hoisted_4 = { class: "flex-center mt-24 api-key-container" }
const _hoisted_5 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_6 = {
  for: "",
  class: "label"
}
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "flex-center" }
const _hoisted_9 = { class: "fs-12 fw-700" }
const _hoisted_10 = {
  key: 1,
  class: "btn02 btn-48 btn-primary btn02-disable flex-mid mr-12",
  style: {"width":"12rem"}
}
const _hoisted_11 = {
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve",
  style: {"width":"50%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("myAccount.API_Key")), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("myAccount.API_Key_intro")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            class: "input",
            type: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiKey) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.apiKey]
          ]),
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("placeholder.Key")), 1)
        ]),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyKey && _ctx.copyKey(...args))),
          class: _normalizeClass(["btn-48 flex-mid", `${
            _ctx.canRegenerate ? 'btn02-outline' : 'btn02 btn-primary btn02-disable'
          }`]),
          style: {"width":"13rem"}
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            alt: "icons_clone_20px",
            class: "icon-16 mr-5"
          }, null, -1)),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("btn.Copy_link")), 1)
        ], 2),
        _createElementVNode("div", _hoisted_8, [
          (!_ctx.regenerateButtonLoading)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.regenerate && _ctx.regenerate(...args))),
                class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.canRegenerate ? '' : 'btn02-disable'}`]),
                style: {"width":"12rem"}
              }, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("btn.Regenerate")), 1)
              ], 2))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(), _createElementBlock("svg", _hoisted_11, _cache[4] || (_cache[4] = [
                  _createElementVNode("path", {
                    fill: "#fff",
                    d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                  }, [
                    _createElementVNode("animateTransform", {
                      attributeName: "transform",
                      attributeType: "XML",
                      type: "rotate",
                      dur: "1s",
                      from: "0 50 50",
                      to: "360 50 50",
                      repeatCount: "indefinite"
                    })
                  ], -1)
                ])))
              ]))
        ])
      ])
    ])
  ]))
}