import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "box-card pt-28 pb-28 pl-40 pr-40" }
const _hoisted_2 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_3 = { class: "fs-16 fw-700 fc-primary mt-12" }
const _hoisted_4 = { class: "fs-14 mt-12" }
const _hoisted_5 = { class: "fs-14 fw-700 mt-32" }
const _hoisted_6 = { class: "flex-center mt-24" }
const _hoisted_7 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_8 = {
  for: "",
  class: "label"
}
const _hoisted_9 = {
  class: "fs-14 fw-700",
  style: {"margin-top":"5rem"}
}
const _hoisted_10 = { class: "flex-center mt-24" }
const _hoisted_11 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  for: "",
  class: "label"
}
const _hoisted_14 = {
  key: 0,
  class: "label-warnning"
}
const _hoisted_15 = {
  key: 0,
  class: "fs-14 fw-700",
  style: {"margin-top":"5rem"}
}
const _hoisted_16 = { class: "mt-20" }
const _hoisted_17 = { class: "taqble-referrals" }
const _hoisted_18 = { class: "date" }
const _hoisted_19 = { class: "ellipsis" }
const _hoisted_20 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.Special_offer")), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.Refer_a_friend_and")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.When_a_friend_signs")), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.Your_unique_referral_link")) + ": ", 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createElementVNode("input", {
            class: "input",
            type: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.refferralLink) = $event)),
            disabled: ""
          }, null, 512), [
            [_vModelText, _ctx.refferralLink]
          ]),
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("placeholder.Code")), 1)
        ]),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyLink())),
          class: "btn02 btn-48 btn-primary flex-mid ml-16",
          style: {"width":"8.6rem"}
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Copy")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.Email_your_invite")) + ": ", 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["input", `${
              !_ctx.validateInviteEmail &&
              _ctx.inviteEmailErrorMsg &&
              _ctx.inviteEmail.length > 5
                ? 'validate'
                : ''
            }`]),
            type: "email",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inviteEmail) = $event)),
            placeholder: _ctx.$t('placeholder.Enter_your_email')
          }, null, 10, _hoisted_12), [
            [_vModelText, _ctx.inviteEmail]
          ]),
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("btn.Email")), 1),
          (_ctx.inviteEmailErrorMsg)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, " ⚠ " + _toDisplayString(_ctx.inviteEmailErrorMsg), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sendInvite())),
          class: "btn02 btn-48 btn-primary flex-mid ml-16",
          style: {"width":"8.6rem"}
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Send")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      (_ctx.referrals && _ctx.referrals.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t("myAccount.refer_a_friend.Successful_Referrals")), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("table", _hoisted_17, [
          _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td"),
              _createElementVNode("td", { style: {"width":"12rem"} }),
              _createElementVNode("td"),
              _createElementVNode("td")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.referrals, (value, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: index + '_tr'
              }, [
                _createElementVNode("td", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.referralDate(value.createdAt)), 1)
                ]),
                _createElementVNode("td", _hoisted_20, _toDisplayString(value.firstName + " " + String(value.lastName)[0]), 1),
                _cache[4] || (_cache[4] = _createElementVNode("td", { class: "description" }, [
                  _createElementVNode("div", { class: "ellipsis" }, _toDisplayString(`Signed up`))
                ], -1)),
                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "credits" }, _toDisplayString(`+10 Credits`), -1))
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}