import { createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box-card pt-28 pb-28 pl-40 pr-40" }
const _hoisted_2 = { class: "mt-32" }
const _hoisted_3 = { class: "input-container-02" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  for: "",
  class: "label"
}
const _hoisted_6 = { class: "mt-32 flex-center" }
const _hoisted_7 = {
  class: "input-container-02 mr-12",
  style: {"width":"50%"}
}
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = {
  for: "",
  class: "label"
}
const _hoisted_10 = {
  class: "input-container-02 ml-12",
  style: {"width":"50%"}
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  for: "",
  class: "label"
}
const _hoisted_13 = {
  class: "mt-32 relative",
  style: {"width":"100%"}
}
const _hoisted_14 = { class: "fs-12 fw-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_select = _resolveComponent("custom-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-16 fw-700 fc-primary" }, "Personal Information", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["input", `${!_ctx.user.email ? 'validate' : ''}`]),
          type: "email",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
          placeholder: _ctx.$t('createAccount.Enter_your_email'),
          disabled: ""
        }, null, 10, _hoisted_4), [
          [_vModelText, _ctx.user.email]
        ]),
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("btn.Email")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["input", `${!_ctx.user.firstName ? 'validate' : ''}`]),
          type: "email",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstName) = $event)),
          placeholder: _ctx.$t('createAccount.Enter_your_first_name')
        }, null, 10, _hoisted_8), [
          [_vModelText, _ctx.user.firstName]
        ]),
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("createAccount.Name")), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _withDirectives(_createElementVNode("input", {
          class: _normalizeClass(["input", `${!_ctx.user.lastName ? 'validate' : ''}`]),
          type: "email",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastName) = $event)),
          placeholder: _ctx.$t('createAccount.Enter_your_last_name')
        }, null, 10, _hoisted_11), [
          [_vModelText, _ctx.user.lastName]
        ]),
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("createAccount.Last_Name")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_custom_select, {
        ref: "selectedLanguage",
        value: _ctx.langSelcted,
        options: _ctx.langOptional,
        placeholder: _ctx.$t('createAccount.Choose_language'),
        label: _ctx.$t('createAccount.Choose_language'),
        onInput: _ctx.handleLang,
        disableAll: true
      }, null, 8, ["value", "options", "placeholder", "label", "onInput"])
    ]),
    _createElementVNode("div", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveMyProfile())),
      class: "mt-32 btn02 btn-48 btn-primary flex-mid",
      style: {"width":"10rem"}
    }, [
      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t("btn.Save")), 1)
    ])
  ]))
}