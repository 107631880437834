import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/icons/navigate_right_black_24px.png'
import _imports_1 from '@/assets/icons/white_check_2_32px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "body-width-reponsive-max" }
const _hoisted_3 = { class: "nav-bar-container flex-center" }
const _hoisted_4 = { class: "flex-center" }
const _hoisted_5 = {
  class: "body-width",
  id: "my_account"
}
const _hoisted_6 = { style: {"margin-top":"6.4rem"} }
const _hoisted_7 = {
  key: 0,
  class: "body-container-with-step",
  style: {"margin-top":"6.4rem","margin-bottom":"6.4rem","grid-template-columns":"20rem calc(100% - 44rem)","gap":"13.6rem"}
}
const _hoisted_8 = { class: "page-menu-container vertical" }
const _hoisted_9 = { class: "input-container-02 mb-24" }
const _hoisted_10 = { class: "menu-inner" }
const _hoisted_11 = { class: "step-name" }
const _hoisted_12 = { class: "menu-inner" }
const _hoisted_13 = { class: "step-name" }
const _hoisted_14 = { class: "menu-inner" }
const _hoisted_15 = { class: "step-name" }
const _hoisted_16 = { class: "menu-inner" }
const _hoisted_17 = { class: "step-name" }
const _hoisted_18 = { class: "menu-inner" }
const _hoisted_19 = { class: "step-name" }
const _hoisted_20 = { class: "menu-inner" }
const _hoisted_21 = { class: "step-name" }
const _hoisted_22 = {
  class: "",
  style: {}
}
const _hoisted_23 = { class: "remove-header remove-padding-modal" }
const _hoisted_24 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_25 = { class: "fs-18 fw-700" }
const _hoisted_26 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_27 = ["placeholder"]
const _hoisted_28 = { class: "label" }
const _hoisted_29 = { class: "content-inner" }
const _hoisted_30 = { style: {"color":"#696c80","font-family":"Urbanist","font-size":"12px","font-style":"normal","font-weight":"700","line-height":"160%"} }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "label-wrapper" }
const _hoisted_33 = {
  class: "label",
  style: {}
}
const _hoisted_34 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_35 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_my_profile = _resolveComponent("my-profile")!
  const _component_my_company = _resolveComponent("my-company")!
  const _component_my_team = _resolveComponent("my-team")!
  const _component_plan_and_billing = _resolveComponent("plan-and-billing")!
  const _component_refer_a_friend = _resolveComponent("refer-a-friend")!
  const _component_api_key = _resolveComponent("api-key")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backLastPage())),
              class: "btn-left"
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "icons_navigate_right_black_24px",
                class: "icon-16 animetion",
                style: {"transform":"rotate(180deg)"}
              }, null, -1)
            ])),
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "label-container" }, [
              _createElementVNode("div", { class: "nav-text-header" }, "My Account")
            ], -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.activeTeamId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTeamId) = $event)),
                    class: _normalizeClass(`input-44-0000000d select no-icon tag-primary-color`),
                    onChange: _ctx.changeTeam
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('My_Profile')}`),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.active('My_Profile')))
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("myAccount.My_Profile")), 1)
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('My_Company')}`),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.active('My_Company')))
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("myAccount.My_Company")), 1)
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('My_Team')}`),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.active('My_Team')))
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("myAccount.My_Team")), 1)
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('Plan_Billing')}`),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.active('Plan_Billing')))
                }, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("myAccount.Plan_and_Billing")), 1)
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('Refer_a_friend')}`),
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.active('Refer_a_friend')))
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("myAccount.Refer_a_friend")), 1)
                  ])
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(`menu ${_ctx.activeClass('API_Key')}`),
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.active('API_Key')))
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("myAccount.API_Key")), 1)
                  ])
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_22, [
                (_ctx.activeTab == 'My_Profile')
                  ? (_openBlock(), _createBlock(_component_my_profile, { key: 0 }))
                  : (_ctx.activeTab == 'My_Company')
                    ? (_openBlock(), _createBlock(_component_my_company, { key: 1 }))
                    : (_ctx.activeTab == 'My_Team')
                      ? (_openBlock(), _createBlock(_component_my_team, {
                          key: 2,
                          "team-id": _ctx.activeTeamId
                        }, null, 8, ["team-id"]))
                      : (_ctx.activeTab == 'Plan_Billing')
                        ? (_openBlock(), _createBlock(_component_plan_and_billing, { key: 3 }))
                        : (_ctx.activeTab == 'Refer_a_friend')
                          ? (_openBlock(), _createBlock(_component_refer_a_friend, { key: 4 }))
                          : (_ctx.activeTab == 'API_Key')
                            ? (_openBlock(), _createBlock(_component_api_key, {
                                key: 5,
                                "team-id": _ctx.activeTeamId
                              }, null, 8, ["team-id"]))
                            : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.newTeamModalStatus,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newTeamModalStatus) = $event)),
        width: "560px",
        ref: "newTeamModal",
        "before-close": _ctx.beforeCloseTeamModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("myAccount.Create_a_team")), 1),
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t("myAccount.To_invite_members_to_a_team")), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["input-container-02", `${_ctx.errors.name ? 'error' : ''}`])
            }, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(`input ${_ctx.errors.name ? 'validate-true' : 'validate-false'}`),
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.name) = $event)),
                type: "text",
                placeholder: _ctx.$t('myAccount.Team_name')
              }, null, 10, _hoisted_27), [
                [_vModelText, _ctx.form.name]
              ]),
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("myAccount.Team_name")), 1)
            ], 2),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("myAccount.Copy_from_Personal_Account")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copyOptions, (data) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: data.value,
                  class: _normalizeClass(["option-card item flex-center", `${_ctx.copyOptionCls(data.value)}`]),
                  onClick: ($event: any) => (_ctx.selectCopyOption(data.value))
                }, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", { class: "checkbox flex-mid" }, [
                    _createElementVNode("div", { class: "checked flex-mid" }, [
                      _createElementVNode("img", {
                        src: _imports_1,
                        alt: "icons_white_check_2_32px",
                        class: "icon-12"
                      })
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.lang(data, "label")), 1)
                  ])
                ], 10, _hoisted_31))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid mr-12", { 'disable-true': _ctx.createTeamDisabled }]),
                style: {"width":"72px"},
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.createTeam && _ctx.createTeam(...args)))
              }, [
                (_ctx.createTeamLoading)
                  ? (_openBlock(), _createElementBlock("svg", _hoisted_35, _cache[15] || (_cache[15] = [
                      _createElementVNode("path", {
                        fill: "#aaa",
                        d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      }, [
                        _createElementVNode("animateTransform", {
                          attributeName: "transform",
                          attributeType: "XML",
                          type: "rotate",
                          dur: "1s",
                          from: "0 50 50",
                          to: "360 50 50",
                          repeatCount: "indefinite"
                        })
                      ], -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["btn-48 flex-mid btn02-outline", { 'disable-true': _ctx.createTeamDisabled }]),
                style: {"width":"72px"},
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closeTeamModal && _ctx.closeTeamModal(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "before-close"])
    ])
  ], 64))
}