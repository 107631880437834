import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "box-card pt-28 pb-28 pl-40 pr-40" }
const _hoisted_2 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_3 = { class: "flex-center" }
const _hoisted_4 = {
  key: 0,
  class: "fs-16 fw-700 fc-primary"
}
const _hoisted_5 = {
  key: 1,
  class: "fs-16 fw-700 fc-primary"
}
const _hoisted_6 = {
  key: 2,
  class: "fs-16 fw-700 fc-primary"
}
const _hoisted_7 = {
  class: "ml-auto fs-12",
  style: {"color":"#696c80"}
}
const _hoisted_8 = {
  key: 0,
  class: ""
}
const _hoisted_9 = {
  key: 1,
  class: ""
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "input-with-icon mt-12" }
const _hoisted_12 = { class: "progress" }
const _hoisted_13 = {
  class: "fs-14 mt-14 fw-500",
  style: {"color":"#696c80"}
}
const _hoisted_14 = { class: "fs-14 fw-700 flex mt-22" }
const _hoisted_15 = { class: "box-content mt-8" }
const _hoisted_16 = { class: "grid-2-50" }
const _hoisted_17 = { class: "text-des flex-mid-list" }
const _hoisted_18 = {
  class: "grid-2-pand-adn-billing",
  style: {"margin-bottom":"72px"}
}
const _hoisted_19 = { class: "left-link" }
const _hoisted_20 = {
  class: "box-card pt-28 pb-28 pl-40 pr-40 mt-32",
  style: {"background-color":"#fff"}
}
const _hoisted_21 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_22 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_23 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_24 = { class: "fs-16 fw-700 fc-primary mb-16" }
const _hoisted_25 = { style: {"display":"flex","flex-direction":"column","margin-left":"auto"} }
const _hoisted_26 = {
  class: "input-container-02",
  style: {"min-width":"162px"}
}
const _hoisted_27 = { class: "fs-14 mb-16" }
const _hoisted_28 = { class: "" }
const _hoisted_29 = { class: "flex-center mt-28" }
const _hoisted_30 = { class: "fs-12 fw-500" }
const _hoisted_31 = {
  key: 0,
  class: "font-pay"
}
const _hoisted_32 = {
  class: "box-card p-24 mt-32",
  style: {"background-color":"#fff","display":"none","min-height":"198px"}
}
const _hoisted_33 = {
  key: 0,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_34 = {
  key: 1,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_35 = {
  key: 2,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_36 = {
  key: 3,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_37 = {
  key: 4,
  class: "fs-16 fw-700 fc-primary mb-16"
}
const _hoisted_38 = {
  key: 5,
  class: "fs-16 fw-700 fc-primary mb-16"
}
const _hoisted_39 = { class: "fs-14 mb-16 fw-400" }
const _hoisted_40 = { class: "fw-700" }
const _hoisted_41 = { class: "flex-center" }
const _hoisted_42 = { class: "fs-12 fw-500" }
const _hoisted_43 = { class: "fs-12 fw-500" }
const _hoisted_44 = { class: "fs-12 fw-500" }
const _hoisted_45 = { class: "fs-12 fw-500" }
const _hoisted_46 = { class: "relative" }
const _hoisted_47 = {
  key: 0,
  class: "font-pay"
}
const _hoisted_48 = { class: "right-link" }
const _hoisted_49 = {
  class: "box-card pt-28 pb-28 pl-40 pr-40 mt-32",
  style: {"background-color":"#fff"}
}
const _hoisted_50 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_51 = { class: "fs-16 fw-700 fc-primary mb-16" }
const _hoisted_52 = {
  class: "fs-14",
  style: {"color":"#696c80"}
}
const _hoisted_53 = { class: "" }
const _hoisted_54 = { class: "fw-700" }
const _hoisted_55 = {
  href: "mailto:sales@happily.ai",
  style: {"color":"#30ccb4","text-decoration":"none"}
}
const _hoisted_56 = { class: "flex-center mt-28" }
const _hoisted_57 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_58 = ["placeholder"]
const _hoisted_59 = {
  for: "",
  class: "label"
}
const _hoisted_60 = { class: "fs-12 fw-500" }
const _hoisted_61 = { class: "relative" }
const _hoisted_62 = {
  key: 0,
  class: "font-pay abosulute"
}
const _hoisted_63 = { class: "remove-header remove-padding-modal" }
const _hoisted_64 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_65 = { class: "fs-18 fw-700" }
const _hoisted_66 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_67 = { class: "input-container-02" }
const _hoisted_68 = { key: 0 }
const _hoisted_69 = { class: "fs-12 fw-500" }
const _hoisted_70 = { class: "fs-12 fw-500" }
const _hoisted_71 = { class: "fs-12 fw-500" }
const _hoisted_72 = { class: "fs-12 fw-500" }
const _hoisted_73 = { class: "credit-rows" }
const _hoisted_74 = { class: "credit-row" }
const _hoisted_75 = { class: "labels-group" }
const _hoisted_76 = { class: "title" }
const _hoisted_77 = { class: "desc" }
const _hoisted_78 = { class: "fs-12 fw-500" }
const _hoisted_79 = { class: "credit-row" }
const _hoisted_80 = { class: "labels-group" }
const _hoisted_81 = { class: "title" }
const _hoisted_82 = { class: "desc" }
const _hoisted_83 = { class: "fs-12 fw-500" }
const _hoisted_84 = { class: "credit-row" }
const _hoisted_85 = { class: "labels-group" }
const _hoisted_86 = { class: "title" }
const _hoisted_87 = { class: "desc" }
const _hoisted_88 = { class: "fs-12 fw-500" }
const _hoisted_89 = {
  class: "mb-20 flex-mid m-auto",
  style: {"margin-top":"6.2rem","width":"36rem","display":"none"}
}
const _hoisted_90 = {
  class: "input-with-icon",
  style: {"width":"23rem"}
}
const _hoisted_91 = ["placeholder"]
const _hoisted_92 = { class: "fs-16 fw-700" }
const _hoisted_93 = { class: "remove-header remove-padding-modal" }
const _hoisted_94 = { class: "pb-42 pt-42 pl-32 pr-32 fc-1B1C1E" }
const _hoisted_95 = { class: "title-monthly" }
const _hoisted_96 = {
  class: "subtitle-mothly mt-20",
  style: {"margin-bottom":"5.6rem"}
}
const _hoisted_97 = { class: "fs-12 fw-500" }
const _hoisted_98 = { class: "sub-btn" }
const _hoisted_99 = { class: "fs-12 fw-500" }
const _hoisted_100 = { class: "fs-12 fw-500" }
const _hoisted_101 = { class: "sub-btn" }
const _hoisted_102 = { class: "fs-12 fw-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("myAccount.planAndBill.Your_current_plan")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_ctx.isUnlimited)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString("Unlimited Plan")))
            : (_ctx.yourPlan)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.yourPlan), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("myAccount.planAndBill.Free_Plan")), 1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (
            _ctx.yourPlan == 'Monthly Subscription' ||
            _ctx.yourPlan == 'Annual Subscription'
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("myAccount.planAndBill.Unlimited_credits")), 1))
            : (_ctx.yourPlan == 'Unlimited')
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.credit) + "/" + _toDisplayString(_ctx.$t("myAccount.planAndBill.Unlimited_credits_used")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.credit) + "/" + _toDisplayString(_ctx.creditTotal) + " " + _toDisplayString(_ctx.$t("myAccount.planAndBill.credits_used")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_el_progress, {
            percentage: _ctx.percentage,
            "show-text": false,
            color: _ctx.color,
            "stroke-width": 2
          }, null, 8, ["percentage", "color"])
        ])
      ]),
      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("myAccount.planAndBill.A_credit_gets_used")) + " " + _toDisplayString(_ctx.$t("myAccount.planAndBill.You_must_have_available")), 1),
      _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("myAccount.planAndBill.All_plans_have_access_to_the_same_benefit")), 1),
      _cache[21] || (_cache[21] = _createElementVNode("form", {
        id: "checkoutForm",
        class: "mt-32",
        method: "POST",
        action: "/charge",
        style: {"display":"none"}
      }, [
        _createElementVNode("input", {
          type: "hidden",
          name: "omiseToken"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "omiseSource"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "amount"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "currency"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "description"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "id"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "redirectUri"
        }),
        _createElementVNode("button", {
          type: "submit",
          id: "checkoutButton"
        }, "Pay with Omise")
      ], -1)),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItemPay, (value, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'text_pen' + index,
              class: "value-container"
            }, [
              _cache[20] || (_cache[20] = _createElementVNode("div", { class: "checkbox flex-center" }, [
                _createElementVNode("svg", {
                  width: "16",
                  height: "17",
                  viewBox: "0 0 16 17",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M6.24265 10.6126L12.3709 4.48438L13.3137 5.42718L6.24265 12.4982L2 8.25563L2.94281 7.31283L6.24265 10.6126Z",
                    fill: "#22BC66"
                  })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("myAccount.planAndBill." + value.name)), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)
              ]),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("myAccount.planAndBill.Unlimited")), 1)
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_custom_select, {
                  ref: "currencyOptions",
                  value: _ctx.currencySelected,
                  options: _ctx.currencyOptions,
                  "x-placeholder": _ctx.$t('createAccount.Choose_language'),
                  "x-label": _ctx.$t('createAccount.Choose_language'),
                  onInput: _ctx.handleCurrency,
                  disableAll: true
                }, null, 8, ["value", "options", "x-placeholder", "x-label", "onInput"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t(
              "myAccount.planAndBill.A_one_time_purchase_to_get_unlimited_credits"
            )), 1)
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openPayUnlimitedModalStatusDetail && _ctx.openPayUnlimitedModalStatusDetail(...args))),
              class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.isUnlimited ? 'disable-true' : ''}`]),
              style: {"min-width":"7rem"}
            }, [
              _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.buyCreditLabel), 1)
            ], 2),
            (_ctx.yourPlan)
              ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t("myAccount.planAndBill.Limited_time_offer")), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_32, [
          (_ctx.yourPlan && _ctx.yourPlan == 'Pay as you go')
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)
              ]))
            : (_ctx.yourPlan && _ctx.yourPlan == 'Monthly Subscription')
              ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Manage_your_plan")), 1)
                ]))
              : (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Manage_your_plan")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)),
          (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Annual_Subscriptionogo")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Monthly_Subscription")), 1)
              ])),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.For_businesses_wiht_high")), 1),
            _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("myAccount.planAndBill._49_per_month")), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.for_unlimited_credits")), 1)
          ]),
          _createElementVNode("div", _hoisted_41, [
            (_ctx.yourPlan && _ctx.yourPlan == 'Pay as you go')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openmonthlySubscriptionModalStatusDetail && _ctx.openmonthlySubscriptionModalStatusDetail(...args))),
                  class: "btn02 btn-48 btn-primary flex-mid",
                  style: {"min-width":"7rem"}
                }, [
                  _createElementVNode("span", _hoisted_42, _toDisplayString(_ctx.$t("myAccount.planAndBill.Subscribe")), 1)
                ]))
              : (_ctx.yourPlan && _ctx.yourPlan == 'Monthly Subscription')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.subscribeAnnual && _ctx.subscribeAnnual(...args))),
                    class: "btn02 btn-48 btn-primary flex-mid",
                    style: {"min-width":"7rem"}
                  }, [
                    _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.$t("myAccount.planAndBill.Change_to_Annual")), 1)
                  ]))
                : (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.subscribeMonthly && _ctx.subscribeMonthly(...args))),
                      class: "btn02 btn-48 btn-primary flex-mid",
                      style: {"min-width":"7rem"}
                    }, [
                      _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("myAccount.planAndBill.Change_to_Monthly")), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openmonthlySubscriptionModalStatusDetail && _ctx.openmonthlySubscriptionModalStatusDetail(...args))),
                      class: "btn02 btn-48 btn-primary flex-mid",
                      style: {"min-width":"7rem"}
                    }, [
                      _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("myAccount.planAndBill.Subscribe")), 1)
                    ])),
            _createElementVNode("div", _hoisted_46, [
              (_ctx.yourPlan != 'Annual Subscription')
                ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.$t("myAccount.planAndBill.Select_the_annual_plan")), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_48, [
        _createElementVNode("div", _hoisted_49, [
          _createElementVNode("div", _hoisted_50, _toDisplayString(_ctx.$t("myAccount.planAndBill.Coupon")), 1),
          _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem_Free_Credits")), 1),
          _createElementVNode("div", _hoisted_52, [
            _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t("myAccount.planAndBill.Enter_your_coupon")), 1),
            _createElementVNode("span", _hoisted_54, [
              _createElementVNode("a", _hoisted_55, _toDisplayString(_ctx.$t("myAccount.planAndBill.sales_happily_ai")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", _hoisted_57, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["input", `${_ctx.redeemCouponFailed ? 'validate' : ''}`]),
                type: "email",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.couponCode) = $event)),
                placeholder: _ctx.$t('placeholder.Enter_coupon_code')
              }, null, 10, _hoisted_58), [
                [_vModelText, _ctx.couponCode]
              ]),
              _createElementVNode("label", _hoisted_59, _toDisplayString(_ctx.$t("placeholder.Code")), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.redeemCoupon && _ctx.redeemCoupon(...args))),
              class: "btn02 btn-48 btn-primary flex-mid ml-16",
              style: {"min-width":"7rem"}
            }, [
              _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_61, [
            (_ctx.redeemCouponFailed && _ctx.couponCode)
              ? (_openBlock(), _createElementBlock("span", _hoisted_62, _toDisplayString(_ctx.$t("myAccount.planAndBill.We_re_sorry")), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_63, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.payAsYouGoModalStatus,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.payAsYouGoModalStatus) = $event)),
        width: "560px",
        ref: "payAsYouGoModalModal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.$t("myAccount.planAndBill.PAY_AS_YOU_GO")), 1),
            _createElementVNode("div", _hoisted_66, _toDisplayString(_ctx.$t("myAccount.planAndBill.Available_credits_allow_you")), 1),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_67, [
                _createVNode(_component_custom_select, {
                  ref: "currencyOptions",
                  value: _ctx.currencySelected,
                  options: _ctx.currencyOptions,
                  "x-placeholder": _ctx.$t('createAccount.Choose_language'),
                  "x-label": _ctx.$t('createAccount.Choose_language'),
                  onInput: _ctx.handleCurrency,
                  disableAll: true
                }, null, 8, ["value", "options", "x-placeholder", "x-label", "onInput"])
              ])
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                  _createElementVNode("div", {
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.payAsYouGo('PAYG_10_1000'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                  }, [
                    _createElementVNode("span", _hoisted_69, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_10_for_S10")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.payAsYouGo('PAYG_20_1800'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_70, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_20_for_S18")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.payAsYouGo('PAYG_30_2400'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_71, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_30_for_S24")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.payAsYouGo('PAYG_40_2800'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_40_for_S28")), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_73, [
              _createElementVNode("div", _hoisted_74, [
                _createElementVNode("div", _hoisted_75, [
                  _createElementVNode("div", _hoisted_76, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits", { credits: 10 })), 1),
                  _createElementVNode("div", _hoisted_77, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_10_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.payAsYouGo(`PAYG_10_${_ctx.sum_10_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_78, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: _ctx.sum_10_credits,
                })), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_79, [
                _createElementVNode("div", _hoisted_80, [
                  _createElementVNode("div", _hoisted_81, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits_discount", {
                    credits: 20,
                    discount: 10,
                  })), 1),
                  _createElementVNode("div", _hoisted_82, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_20_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.payAsYouGo(`PAYG_20_${_ctx.sum_20_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: _ctx.sum_20_credits,
                })), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_84, [
                _createElementVNode("div", _hoisted_85, [
                  _createElementVNode("div", _hoisted_86, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits_discount", {
                    credits: 50,
                    discount: 30,
                  })), 1),
                  _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_50_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.payAsYouGo(`PAYG_50_${_ctx.sum_50_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_88, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: Number(_ctx.sum_50_credits).toLocaleString("en-US"),
                })), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_89, [
              _createElementVNode("div", _hoisted_90, [
                _withDirectives(_createElementVNode("input", {
                  class: "input input-44",
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.couponCode) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('placeholder.Coupon_code')
                }, null, 8, _hoisted_91), [
                  [_vModelText, _ctx.couponCode]
                ])
              ]),
              _createElementVNode("div", {
                onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.redeemCoupon && _ctx.redeemCoupon(...args))),
                class: "btn02 btn-48 btn-primary flex-mid ml-12",
                style: {"height":"4.2rem","width":"8rem"}
              }, [
                _createElementVNode("span", _hoisted_92, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_93, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.monthlySubscriptionModalStatus,
        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.monthlySubscriptionModalStatus) = $event)),
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_94, [
            _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.$t("myAccount.planAndBill.SUBSCRIPTION")), 1),
            _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.$t("myAccount.planAndBill.Access_unlimited_credits_during")), 1),
            _createElementVNode("div", {
              onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.subscribeMonthly && _ctx.subscribeMonthly(...args))),
              class: "btn02 btn-48 btn-primary flex-mid btn-monthly"
            }, [
              _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.$t("myAccount.planAndBill.Monthly_for_S49")), 1)
            ]),
            _createElementVNode("div", _hoisted_98, [
              _createElementVNode("span", _hoisted_99, _toDisplayString(_ctx.$t("myAccount.planAndBill.Billed_today_and_on")), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.subscribeAnnual && _ctx.subscribeAnnual(...args))),
              class: "btn02 btn-48 btn-primary flex-mid btn-monthly"
            }, [
              _createElementVNode("span", _hoisted_100, _toDisplayString(_ctx.$t("myAccount.planAndBill.Annual_for_S441")), 1)
            ]),
            _createElementVNode("div", _hoisted_101, [
              _createElementVNode("span", _hoisted_102, _toDisplayString(_ctx.$t("myAccount.planAndBill.Billed_today_and_next")), 1)
            ]),
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "pt-14 pb-14" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}