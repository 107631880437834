import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/upload_24px.png'


const _hoisted_1 = {
  key: 0,
  id: "company-style"
}
const _hoisted_2 = { class: "my-company-container" }
const _hoisted_3 = {
  key: 0,
  class: "box-card",
  style: {"display":"flex","flex-direction":"column","gap":"16px"}
}
const _hoisted_4 = { class: "fs-16 fw-700 fc-primary" }
const _hoisted_5 = { class: "input-container-02 mt-24" }
const _hoisted_6 = ["placeholder", "disabled"]
const _hoisted_7 = {
  for: "",
  class: "label"
}
const _hoisted_8 = { class: "input-container-02" }
const _hoisted_9 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_10 = {
  class: "input-container-02 x-closable-false",
  style: `height: auto`
}
const _hoisted_11 = {
  class: "label",
  style: {"z-index":"9999"}
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "label" }
const _hoisted_14 = {
  class: "fs-12 mt-24 mb-8 opa-04",
  style: {"display":"none"}
}
const _hoisted_15 = { class: "box-card color-picker-wrapper" }
const _hoisted_16 = { class: "fs-16 fw-700 fc-primary" }
const _hoisted_17 = { class: "uploadfile-container relative mt-16" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  key: 1,
  class: "absolute-mid text-hover center"
}
const _hoisted_21 = { class: "fs-14 fw-700 opa-06 mt-22" }
const _hoisted_22 = {
  class: "fs-12 opa-06 mt-8",
  style: {}
}
const _hoisted_23 = { class: "text-underline" }
const _hoisted_24 = { class: "mt-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (
          _ctx.valuesAndTraits &&
          _ctx.valuesAndTraits.scenarios &&
          _ctx.valuesAndTraits.scenarios.definingYourValues &&
          _ctx.valuesAndTraits.scenarios.definingYourValues.questions
        )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("myAccount.Company_Info")), 1),
                _createElementVNode("div", _hoisted_5, [
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["input", `${_ctx.errors.companyName ? 'validate' : ''}`]),
                    type: "email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetModel.companyName) = $event)),
                    placeholder: _ctx.$t('createAccount.Enter_your_company_name'),
                    disabled: !_ctx.isAdmin
                  }, null, 10, _hoisted_6), [
                    [_vModelText, _ctx.targetModel.companyName]
                  ]),
                  _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("myAccount.Company_Name")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_el_select, {
                        key: 0,
                        modelValue: _ctx.targetModel.typeOfOrg,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetModel.typeOfOrg) = $event)),
                        "x-multiple": "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Eg. Technology Startup",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
              _ctx.errors.typeOfOrgSelected ? 'error' : ''
            }`)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOfOrgOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"]))
                    : (_openBlock(), _createBlock(_component_el_select, {
                        key: 1,
                        modelValue: _ctx.targetModel.typeOfOrg,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.targetModel.typeOfOrg) = $event)),
                        "x-multiple": "",
                        filterable: "",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Eg. Technology Startup",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
              _ctx.errors.typeOfOrgSelected ? 'error' : ''
            }`),
                        disabled: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeOfOrgOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item,
                              label: item,
                              value: item
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"])),
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("toolbox.Type_of_organization")), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.isAdmin)
                    ? (_openBlock(), _createBlock(_component_el_select, {
                        key: 0,
                        modelValue: _ctx.targetModel.coreValues,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.targetModel.coreValues) = $event)),
                        multiple: "",
                        filterable: "",
                        "multiple-limit": "8",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Add up to 8 values",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
              _ctx.errors.coreValuesSelected ? 'error' : ''
            }`),
                        style: `height: auto`
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
                .questions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.id,
                              label: _ctx.lang(item, 'title'),
                              value: _ctx.lang(item, 'title')
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"]))
                    : (_openBlock(), _createBlock(_component_el_select, {
                        key: 1,
                        modelValue: _ctx.targetModel.coreValues,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetModel.coreValues) = $event)),
                        multiple: "",
                        filterable: "",
                        "multiple-limit": "8",
                        "allow-create": "",
                        "default-first-option": "",
                        "reserve-keyword": false,
                        placeholder: "Add up to 8 values",
                        class: _normalizeClass(`input-44-0000000d select no-icon ${
              _ctx.errors.coreValuesSelected ? 'error' : ''
            }`),
                        style: `height: auto`,
                        disabled: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuesAndTraits.scenarios.definingYourValues
                .questions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.id,
                              label: _ctx.lang(item, 'title'),
                              value: _ctx.lang(item, 'title')
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "class"])),
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("toolbox.Core_values")), 1)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(`input-container-02 textarea ${
            _ctx.errors.missionAndVision ? 'error' : ''
          }`)
                }, [
                  _withDirectives(_createElementVNode("textarea", {
                    disabled: !_ctx.isAdmin,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.targetModel.missionAndVision) = $event)),
                    placeholder: "Eg. Create groundbreaking sports innovations, make our products sustainably, build a creative and diverse global team, and make a positive impact in communities where we live and work"
                  }, null, 8, _hoisted_12), [
                    [_vModelText, _ctx.targetModel.missionAndVision]
                  ]),
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("toolbox.Company_Mission_n_Vision")), 1)
                ], 2),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("myAccount.Brand_Color")), 1),
                _createElementVNode("div", {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.saveMyCompany())),
                  class: _normalizeClass(["mt-32 btn02 btn-48 btn-primary flex-mid", { 'btn02-disable': !_ctx.isAdmin }]),
                  style: {"width":"11.4rem"}
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Save")), 1)
                ], 2)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("myAccount.Brand_Assets")), 1),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("input", {
                type: "file",
                onChange: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
                accept: ".png, .jpg, .jpeg, .svg, .gif",
                name: "logoCompany",
                class: "input-file",
                disabled: !_ctx.isAdmin
              }, null, 40, _hoisted_18),
              (_ctx.isImage || (!_ctx.userDetail.imageFile && _ctx.companyLogoUrl))
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.userDetail.imageFile || _ctx.companyLogoUrl,
                    alt: "Selected File Preview",
                    class: "image-upload"
                  }, null, 8, _hoisted_19))
                : _createCommentVNode("", true),
              (!(_ctx.isImage || (!_ctx.userDetail.imageFile && _ctx.companyLogoUrl)))
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "" }, [
                      _createElementVNode("img", {
                        src: _imports_0,
                        alt: "icons_upload_24px"
                      })
                    ], -1)),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("createAccount.Upload_your_Logo")), 1),
                    _createElementVNode("div", _hoisted_22, [
                      _createTextVNode(_toDisplayString(_ctx.$t("createAccount.Drag_drop_or")) + " ", 1),
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("createAccount.choose_file")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("createAccount.to_upload")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_ColorPicker, {
                color: _ctx.targetModel.color,
                "onUpdate:modelValue": _ctx.onColorChange
              }, null, 8, ["color", "onUpdate:modelValue"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}