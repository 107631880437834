import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "box-card" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "fc-primary" }
const _hoisted_4 = { class: "list" }
const _hoisted_5 = { class: "list-item" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { style: {"display":"flex","flex-direction":"row","gap":"12px"} }
const _hoisted_8 = { style: {"width":"100%","text-align":"center"} }
const _hoisted_9 = { style: {"width":"100%","text-align":"center"} }
const _hoisted_10 = { class: "header" }
const _hoisted_11 = { class: "fc-primary" }
const _hoisted_12 = {
  key: 0,
  class: "list"
}
const _hoisted_13 = { class: "title" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 0,
  class: "input-container-02"
}
const _hoisted_16 = {
  key: 1,
  style: {"height":"4.8rem","display":"flex","align-items":"center","color":"#1b1c1e","font-family":"Urbanist","font-size":"14px","font-style":"normal","font-weight":"400","line-height":"26px"}
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 1,
  class: "fs-12 mt-24",
  style: {"color":"#696c80","text-align":"center","width":"100%"}
}
const _hoisted_21 = { class: "remove-header remove-padding-modal" }
const _hoisted_22 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_23 = { class: "fs-18 fw-700" }
const _hoisted_24 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "el-dialog-footer flex-center mt-32" }
const _hoisted_27 = { class: "remove-header remove-padding-modal" }
const _hoisted_28 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_29 = { class: "fs-18 fw-700" }
const _hoisted_30 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_31 = ["placeholder"]
const _hoisted_32 = { class: "label" }
const _hoisted_33 = { class: "el-dialog-footer flex-center mt-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("myAccount.Team_Info")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("strong", null, _toDisplayString(_ctx.team.name), 1),
            _cache[11] || (_cache[11] = _createElementVNode("span", null, "Team Name", -1))
          ]),
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editTeam())),
                  class: "btn02-outline btn-48",
                  style: {"width":"8.6rem"}
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("btn.Edit")), 1)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteTeam())),
                  class: "btn02-outline btn-48",
                  style: {"width":"8.6rem"}
                }, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("btn.Delete")), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.members.length > 1
            ? _ctx.$t("myAccount.Team_Members", { total: _ctx.members.length })
            : _ctx.$t("myAccount.Team_Member")), 1),
        (!_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openInviteMemberModal && _ctx.openInviteMemberModal(...args))),
              class: "btn02 btn-48 btn-primary flex-mid ml-16",
              style: {}
            }, [
              _cache[12] || (_cache[12] = _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M7.33398 7.33398V3.33398H8.66732V7.33398H12.6673V8.66732H8.66732V12.6673H7.33398V8.66732H3.33398V7.33398H7.33398Z",
                  fill: "white"
                })
              ], -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.Invite_Members")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isMember(_ctx.user.email))
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.members, (member, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "list-item"
              }, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("strong", null, [
                    _createTextVNode(_toDisplayString(member.displayed_name || member.email) + " ", 1),
                    (member.active === false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, "(" + _toDisplayString(_ctx.$t("myAccount.Pending")) + ")", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("span", null, _toDisplayString(member.email), 1)
                ]),
                (!_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_el_select, {
                        modelValue: member.role,
                        "onUpdate:modelValue": ($event: any) => ((member.role) = $event),
                        placeholder: "",
                        class: _normalizeClass(`input-44-0000000d select no-icon`),
                        onChange: ($event: any) => (_ctx.changeRole({ email: member.email, role: member.role })),
                        disabled: !_ctx.isAdmin(_ctx.user.email)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roleOptions, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.value,
                              label: item.label,
                              value: item.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "disabled"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("myAccount.role_" + member.role)), 1)),
                (
            !_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email) && _ctx.canInvite(member.email)
          )
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: ($event: any) => (_ctx.sendInvite(member.email)),
                      class: "btn02-outline btn-48",
                      style: {"width":"8.6rem"}
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Send")), 1)
                    ], 8, _hoisted_17))
                  : (!_ctx.isPersonalTeam && _ctx.canLeave(member.email))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        onClick: ($event: any) => (_ctx.doLeaveTeam(member.email)),
                        class: _normalizeClass(["btn02-outline btn-48", { 'btn02-disable': !_ctx.allowToLeave(member.email) }]),
                        style: {"width":"8.6rem"}
                      }, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Leave")), 1)
                      ], 10, _hoisted_18))
                    : (
            !_ctx.isPersonalTeam && _ctx.isAdmin(_ctx.user.email) && _ctx.canRemove(member.email)
          )
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 4,
                          onClick: ($event: any) => (_ctx.removeMember(member.email)),
                          class: "btn02-outline btn-48",
                          style: {"width":"8.6rem"}
                        }, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Remove")), 1)
                        ], 8, _hoisted_19))
                      : (!_ctx.isPersonalTeam)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 5,
                            class: _normalizeClass(["btn02-outline btn-48", { 'btn02-disable': true }]),
                            style: _normalizeStyle([{"width":"8.6rem"}, `visibility: ${_ctx.isAdmin(_ctx.user.email) ? 'visible' : 'hidden'}`])
                          }, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Remove")), 1)
                          ], 4))
                        : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t("myAccount.You_do_not_have_permission_to_access_the_requested_page")), 1)),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_el_dialog, {
          modelValue: _ctx.inviteMemberModalStatus,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.inviteMemberModalStatus) = $event)),
          width: "560px",
          ref: "inviteMemberModal"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("myAccount.Invite_a_Member")), 1),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t("myAccount.Add_a_member_to_your_team_by_email_address")), 1),
              _createElementVNode("div", {
                class: _normalizeClass(["input-container-02", `${_ctx.errors.email ? 'error' : ''}`])
              }, [
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass(`input ${
                _ctx.errors.email ? 'validate-true' : 'validate-false'
              }`),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
                  type: "text",
                  placeholder: `name@company.com`
                }, null, 2), [
                  [_vModelText, _ctx.form.email]
                ]),
                _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("myAccount.Member_Email")), 1)
              ], 2),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", {
                  class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid mr-12", { 'disable-true': false }]),
                  style: {"width":"72px"},
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.addMember && _ctx.addMember(...args)))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Submit")), 1)
                ]),
                _createElementVNode("div", {
                  class: "btn-48 flex-mid btn02-outline",
                  style: {"width":"72px"},
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.inviteMemberModalStatus = false))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.editTeamModalStatus,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editTeamModalStatus) = $event)),
        width: "560px",
        ref: "editTeamModal",
        "before-close": _ctx.beforeCloseTeamModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("myAccount.Edit_a_team")), 1),
            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("myAccount.Please_enter_a_name_that_is_unique_and_recognizable")), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["input-container-02", `${_ctx.teamErrors.name ? 'error' : ''}`])
            }, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(`input ${
              _ctx.teamErrors.name ? 'validate-true' : 'validate-false'
            }`),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.teamForm.name) = $event)),
                type: "text",
                placeholder: _ctx.$t('myAccount.Team_name')
              }, null, 10, _hoisted_31), [
                [_vModelText, _ctx.teamForm.name]
              ]),
              _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t("myAccount.Team_name")), 1)
            ], 2),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("div", {
                class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid mr-12", { 'disable-true': false }]),
                style: {"width":"72px"},
                onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.doEditTeam && _ctx.doEditTeam(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Save")), 1)
              ]),
              _createElementVNode("div", {
                class: "btn-48 flex-mid btn02-outline",
                style: {"width":"72px"},
                onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.closeTeamModal && _ctx.closeTeamModal(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("btn.Cancel")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "before-close"])
    ])
  ], 64))
}